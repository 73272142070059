export const routes = {
	dashboard: "/",
	users: "/users",
	user: "/users/:userId",
	manualCredit: "/manual-credit",
	comments: "/comments",
	reportPage: "/report-page",
	login: "/login",
	signUp: "/signup",
};

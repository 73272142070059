import styled from "styled-components";
import Colour from "../../utils/Colour";

export const LayoutContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	position: relative;
`;

export const SideBarContainer = styled.div`
	width: 20%;
	max-width: 300px;
	display: flex;
	flex-direction: column;
	padding: 0.5rem;
	padding-right: 0;
	box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
`;
export const MailDripLogoContain = styled.header`
	width: 100px;
	height: 70px;
	display: flex;
	align-items: center;

	span {
		margin: 0px 9.14738px;
		font-weight: 600;
		font-size: 21.039px;
		line-height: 22px;
		color: ${Colour.dripBlue};
	}
`;
export const MailDripLogo = styled.img`
	width: 27px;
	height: 27px;
`;
export const SideBarContent = styled.div`
	margin: 1rem 0 0 0;
	padding: 0 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	${"" /* min-height: 80vh; */}
	position: relative;

	$ > span {
	}
`;
export const SideBarNavLink = styled.div``;
export const SideBarNavItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.5rem;
	background-color: ${({ isActive }) => (isActive ? Colour.bgBlue : "transparent")};

	& > div {
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
		color: ${({ isActive }) => (isActive ? Colour.dripBlue : Colour.textGreyrey2)};
	}
	& > span {
		color: ${Colour.dripBlue};
		background-color: ${Colour.textWhite};
		font-weight: 400;
		font-size: 18px;
		line-height: 27px;
		padding: 0.3rem;
	}
`;
export const LogoutContainer = styled.div`
	width: fit-content;
	display: flex;
	align-items: center;
	margin: 0.5rem;

	span {
		font-weight: 600;
		font-size: 14.6358px;
		cursor: pointer;
	}
	img {
		cursor: pointer;
	}
`;
export const MainContentContainer = styled.section`
	width: 80%;
	max-width: 100%;
`;
export const MainContentHeaderContain = styled.header`
	width: 100%;
	height: 70px;
	padding: 1rem 1.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0px 2.52px 10.0172px rgba(141, 145, 160, 0.0417275);
`;
export const MainContentHeader = styled.div`
	width: 100%;
	${"" /* text-transform: uppercase; */}
	font-weight: 700;
	font-size: 16px;
`;
export const ProfileSection = styled.div`
	width: fit-content;
	display: flex;
	align-items: center;

	img {
		margin: 0 0.5rem;
	}
`;

export const CommentInModal = styled.div``;

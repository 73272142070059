import { Button } from "antd";
import React, { useState } from "react";
import { errorMessage } from "../utils/getErrors";
import { showToastMessage } from "../utils/Toast";
import { setEmailThreshold } from "../service/serviceInstance";

export const EmailThresholdForm = ({
	threshold,
	setThreshold,
	token,
	userId,
}) => {
	const [loading, setLoading] = useState(false);
	const setUserEmailThreshold = async () => {
		setLoading(true);
		try {
			const result = await setEmailThreshold(userId, token, {
				limit: threshold,
			});
			showToastMessage({
				type: "success",
				title: "Success",
				description: result?.message,
			});
			setLoading(false);
		} catch (error) {
			setLoading(false);
			const errMsg = errorMessage(error);
			setLoading(false);
			showToastMessage({
				type: "error",
				title: "Error",
				description: errMsg || "An error occurred",
			});
		}
	};
	return (
		<div className="short">
			<label className="thresh-label">Set Email Threshold/Hr</label>
			<input
				onChange={(e) => setThreshold(Number(e.target.value))}
				placeholder="0"
				value={threshold}
				className="thresh-input"
			/>
			<Button
				className="thresh-btn"
				loading={loading}
				onClick={() => setUserEmailThreshold()}
			>
				Set Threshold
			</Button>
		</div>
	);
};

import axios from "axios";
// import Cookie from "js-cookie";

// const token = Cookie.get("token");
const API = process.env.REACT_APP_API_URL;
// const ClientUrl = process.env.REACT_APP_CLIENT_URL;

class HttpService {
	// constructor(url) {
	// 	this.token = "cookie";
	// 	this.url = url;
	// }

	getMethod = (url) => {
		return axios.get(url, {
			baseURL: API,
		});
	};
	getMethodWithAuth = (url, token) => {
		return axios.get(url, {
			headers: { "x-access-token": token },
			baseURL: API,
		});
	};
	postMethod = (url, data) => {
		return axios.post(url, data, {
			baseURL: API,
		});
	};
	postMethodWithAuth = (url, data, token) => {
		return axios.post(url, data, {
			headers: { "x-access-token": token },
			baseURL: API,
		});
	};
	putMethodWithAuth = (url, data, token) => {
		return axios.put(url, data, {
			headers: { "x-access-token": token },
			baseURL: API,
		});
	};
}

export default HttpService;

export const httpInstance = new HttpService();

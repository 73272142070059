import { notification } from "antd";
import React from "react";
import { ReactComponent as ErrorIcon } from "../asset/icons/errorIconM.svg";
import { ReactComponent as SuccessIcon } from "../asset/icons/successIconM.svg";
import { ReactComponent as WarningIcon } from "../asset/icons/warningIconM.svg";

export const showToastMessage = ({
	type = required("type"),
	title = required("title"),
	description = required("description"),
	duration = 4,
	placement = "top",
	icon,
}) => {
	const iconImage =
		type === "success" ? (
			<SuccessIcon />
		) : type === "warning" ? (
			<WarningIcon />
		) : type === "error" ? (
			<ErrorIcon />
		) : (
			<WarningIcon />
		);
	notification[type]({
		message: title,
		description,
		duration,
		placement,
		icon: icon ?? iconImage,
		maxCount: 3,
		className: "customNotification",
	});
};

function required(varName) {
	throw new Error(`${varName} is required`);
}

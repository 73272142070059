import { Text } from "./styled/Global.styled";
import PropTypes from "prop-types";

const TextContent = ({
	children,
	fontSize,
	fontWeight,
	colour,
	bg,
	textAlign,
	display,
	onClick,
	plantype,
	status,
	style,
	title,
}) => {
	return (
		<Text
			title={title}
			fontSize={fontSize}
			fontWeight={fontWeight}
			colour={colour}
			bg={bg}
			textAlign={textAlign}
			display={display}
			onClick={onClick}
			plantype={plantype}
			status={status}
			style={style}
		>
			{children}
		</Text>
	);
};

export default TextContent;

TextContent.propTypes = {
	colour: PropTypes.string,
	bg: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element, PropTypes.number]).isRequired,
	fontSize: PropTypes.number,
	fontWeight: PropTypes.string,
	textAlign: PropTypes.oneOf(["left", "right", "center"]),
	display: PropTypes.oneOf(["inline", "block"]),
	onClick: PropTypes.func,
};

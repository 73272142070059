import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Route, Outlet, Navigate } from "react-router-dom";
import { handleRouteAuthStatus } from "../../service/serviceInstance";
import { showToastMessage } from "../../utils/Toast";
import Layout from "../Layout";

export const ProtectedRoute = ({ children, routeName }) => {
	const [isLoading, setIsLoading] = useState(false);
	const token = Cookies.get("token");
	useEffect(() => {
		handleRouteAuthStatus(
			{ cancelLoading: () => setIsLoading(false) },
			{
				showMessage: (description) =>
					showToastMessage({
						type: "warning",
						title: "Session",
						description: "Session Expired",
					}),
			}
		);
	}, []);
	if (!token) {
		// showToastMessage({
		// 	type: "warning",
		// 	title: "Please, login 😊",
		// 	description: "",
		// });
		return <Navigate to="/login" replace />;
	}
	return (
		!isLoading && (
			<Layout routeName={routeName}>
				<Outlet />
			</Layout>
		)
	);
};

export default ProtectedRoute;

import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import Modal from "../components/Modal";
import TextContent from "../components/TextContent";
import { CommentInModal } from "../components/styled/Layout.styled";
import { customDateChk } from "../utils/useDate";

import Button from "../components/Button";
import Colour from "../utils/Colour";
import { CommentPageContain, CommentSectionContainer } from "../components/styled/Comments.styled";
import Tag from "../components/Tag";
import Table from "../components/Table";
import { getComments } from "../service/serviceInstance";
import { errMsgShared } from "../utils/getErrors";
import jsCookie from "js-cookie";
import { truncateString } from "../utils/generics";

const Comments = ({ setRouteName }) => {
	const [commentData, setCommentData] = useState([]);
	const [viewComment, setViewComment] = useState("");
	const [isModalActive, setModalActive] = useState(false);

	const token = jsCookie.get("token");
	useEffect(() => {
		setRouteName("Comments");

		getComments(token)
			.then((result) => {
				// console.log(result);
				setCommentData(result)})
			.catch((error) => {
				errMsgShared(error);
			});
			
		// eslint-disable-next-line
	}, []);
	// console.log({ commentData });
	const columns = [
		{
			title: "Date",
			dataIndex: "id",
			sortDirections: ["descend"],
			width: 110,
			 sorter: (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt),
			// render: (text, record) => <TextContent colour={Colour.neutral2}>{text}</TextContent>,
			render: (text, record) => <TextContent colour={Colour.textBlack2}>
					{record?.createdAt ? customDateChk.convert(record?.createdAt) : "01/01/2022"}
				</TextContent>
		},
		{
			title: "Comment",
			dataIndex: "message",
			sorter: (a, b) => a.message.length - b.message.length,
			render: (text, record) => (
				<TextContent title={text} onClick={() => handleViewComment(record)} fontWeight="500">
					{truncateString(text, 60)}
				</TextContent>
			),
		},
		{
			title: "NPR",
			dataIndex: "ratings",
			sorter: (a, b) => {
				return (a?.rating || 0) - (b?.rating || 0)
			 },
			width: 70,
			render: (text, record) => <TextContent fontWeight="500">{record?.rating || 0 }</TextContent>,
		},
		{
			title: "Type",
			dataIndex: "feedbackStatus",
			sorter: (a, b) => a.feedbackType - b.feedbackType,
			width: 110,
			render: (text, record) => (
				<Tag
					color={record.feedbackType === 0 ? Colour.success : Colour.alert}
					bg={record.feedbackType === 1 ? Colour.bgSuccess : Colour.bgAlert}
				>
					<TextContent>{record.feedbackType === 0 ? "Support" : "Suggestion"}</TextContent>
				</Tag>
			),
		},
		{
			title: "Name",
			dataIndex: "user",
			sorter: (a, b) => a.user?.name.localeCompare(b.user?.name),
			width: 180,
			render: (text, record) => <TextContent fontWeight="500">{text?.name || "No Name"}</TextContent>,
		},
		{
			title: "Email Address",
			dataIndex: "user",
		sorter: (a, b) => a.user?.email.localeCompare(b.user?.email),
			width: 185,
			render: (text, record) => (
				<TextContent title={text?.email} colour={Colour.neutral2}>
					{truncateString(text?.email, 20)}
				</TextContent>
			),
		},
	];
	// eslint-disable-next-line
	const data = [
		{
			key: "1",
			name: "Olayinka Omosehin",
			date: "14/04/2022",
			comment:
				"I really love Maildrip. Can you add a button. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
			npr: "8/10",
			type: "Feedback",
			email: "olayinka.omosehin@charisol.io",
			unread: true,
		},
		{
			key: "2",
			name: "Olayinka Omosehin",
			date: "14/04/2022",
			comment:
				"I really love Maildrip. Can you add a button. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
			npr: "8/10",
			type: "Support",
			email: "olayinka.omosehin@charisol.io",
			unread: true,
		},
		{
			key: "3",
			name: "Olayinka Omosehin",
			date: "14/04/2022",
			comment:
				"I really love Maildrip. Can you add a button. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
			npr: "8/10",
			type: "Support",
			email: "olayinka.omosehin@charisol.io",
			unread: true,
		},
		{
			key: "4",
			name: "Olayinka Omosehin",
			date: "14/04/2022",
			comment:
				"I really love Maildrip. Can you add a button. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
			npr: "8/10",
			type: "Feedback",
			email: "olayinka.omosehin@charisol.io",
			unread: true,
		},
		{
			key: "5",
			name: "Olayinka Omosehin",
			date: "14/04/2022",
			comment:
				"I really love Maildrip. Can you add a button. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
			npr: "8/10",
			type: "Feedback",
			email: "olayinka.omosehin@charisol.io",
			unread: false,
		},
		{
			key: "6",
			name: "Olayinka Omosehin",
			date: "14/04/2022",
			comment:
				"I really love Maildrip. Can you add a button. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
			npr: "8/10",
			type: "Support",
			email: "olayinka.omosehin@charisol.io",
			unread: false,
		},
		{
			key: "7",
			name: "Olayinka Omosehin",
			date: "14/04/2022",
			comment:
				"I really love Maildrip. Can you add a button. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
			npr: "8/10",
			type: "Feedback",
			email: "olayinka.omosehin@charisol.io",
			unread: false,
		},
		{
			key: "8",
			name: "Olayinka Omosehin",
			date: "14/04/2022",
			comment:
				"I really love Maildrip. Can you add a button. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
			npr: "8/10",
			type: "Support",
			email: "olayinka.omosehin@charisol.io",
			unread: false,
		},
		{
			key: "9",
			name: "Olayinka Omosehin",
			date: "14/04/2022",
			comment:
				"I really love Maildrip. Can you add a button. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
			npr: "8/10",
			type: "Feedback",
			email: "olayinka.omosehin@charisol.io",
			unread: false,
		},
		{
			key: "10",
			name: "Olayinka Omosehin",
			date: "14/04/2022",
			comment:
				"I really love Maildrip. Can you add a button. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
			npr: "8/10",
			type: "Support",
			email: "olayinka.omosehin@charisol.io",
			unread: false,
		},
		{
			key: "11",
			name: "Olayinka Omosehin",
			date: "14/04/2022",
			comment:
				"I really love Maildrip. Can you add a button. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
			npr: "8/10",
			type: "Support",
			email: "olayinka.omosehin@charisol.io",
			unread: false,
		},
		{
			key: "12",
			name: "Olayinka Omosehin",
			date: "14/04/2022",
			comment:
				"I really love Maildrip. Can you add a button. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
			npr: "8/10",
			type: "Feedback",
			email: "olayinka.omosehin@charisol.io",
			unread: false,
		},
	];

	const handleViewComment = (record) => {
		setViewComment(record);
		setModalActive(true);
	};
	const onCancel = () => {
		setModalActive(false);
	};
	const onOkay = () => {
		setModalActive(false);
	};
	function onChange(pagination, filters, sorter, extra) {
		// console.log("params", pagination, filters, sorter, extra);
	}

	return (
		<>
			<CommentPageContain>
				{/* <TableTools
					onSearch={setSearchQuery}
					data={data}
					setSearchedData={setSearchedData}
					searchFocus={["email"]}
					searchPlaceholder="Search by email"
					searchValue={searchQuery}
					canSearch
				/> */}
				<Table
					columns={columns}
					data={commentData}
					onChange={onChange}
					scroll={{ x: 900, y: 350 }}
					rowClassName={({ unread }) => unread && "unread-comment"}
				/>
				<Modal isActive={isModalActive} onCancel={onCancel} onOkay={onOkay} title="Comment" okText="Done">
					<CommentInModal>
						<CommentSectionContainer>
							<TextContent colour={Colour.textGreyrey} fontWeight="500">
								{viewComment?.message || <LoadingOutlined />}
							</TextContent>
						</CommentSectionContainer>
						<Button onClick={onOkay}>Done</Button>
					</CommentInModal>
				</Modal>
			</CommentPageContain>
		</>
	);
};

export default Comments;

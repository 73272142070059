import { UseButton } from "./styled/Global.styled";
import TextContent from "./TextContent";

const Button = ({ children, color, bgColor, block, margin, onClick }) => {
	return (
		<UseButton color={color} bgColor={bgColor} block={block} margin={margin} onClick={onClick}>
			<TextContent fontSize={16} fontWeight="500">
				{children}
			</TextContent>
		</UseButton>
	);
};

export default Button;

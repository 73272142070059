import { useEffect } from "react";

const Dashboard = ({ setRouteName }) => {
	useEffect(() => {
		setRouteName("Dashboard");
	}, []);
	return <div>Main Dashboard</div>;
};

export default Dashboard;

import { Usetag } from "./styled/Global.styled";

const Tag = ({ children, color, bg, analytic, plantype }) => {
	return (
		<Usetag color={color} bg={bg} analytic={analytic} plantype={plantype}>
			{children}
		</Usetag>
	);
};

export default Tag;

import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	BillingHistoryContain,
	UsersPageContain,
} from "../components/styled/Users.styled";
import { formatNumber } from "../utils/formatter";
import TextContent from "../components/TextContent";
import Colour from "../utils/Colour";
import Table from "../components/Table";
import {
	getSingleUserBilling,
	getSingleUserData,
} from "../service/serviceInstance";
import { errorMessage } from "../utils/getErrors";
import { showToastMessage } from "../utils/Toast";
import dayjs from "dayjs";
import jsCookie from "js-cookie";
// eslint-disable-next-line no-unused-vars
import { customDateChk, dates } from "../utils/useDate";
import { CardItem } from "../components/CardItem";
import { TextItem } from "../components/TextItem";
import { ReactComponent as MdCampaign } from "@material-design-icons/svg/filled/campaign.svg";
import { ReactComponent as MdEmailRead } from "@material-design-icons/svg/filled/mark_email_read.svg";
import { ReactComponent as MdDiversity } from "@material-design-icons/svg/filled/diversity_3.svg";
import { ReactComponent as MdEmailCredit } from "@material-design-icons/svg/filled/account_balance_wallet.svg";
import { EmailThresholdForm } from "../components/EmailThresholdForm";

const User = ({ setRouteName }) => {
	const [userData, setUserData] = useState(null);
	const [userBillingHistory, setUserBillingHistory] = useState(null);
	const [threshold, setThreshold] = useState(null);
	const [loading, setLoading] = useState(false);
	const { userId } = useParams();

	const token = jsCookie.get("token");
	useEffect(() => {
		setLoading(true);
		setRouteName("User");
		getSingleUserBilling(userId, token)
			.then((result) => setUserBillingHistory(result))
			.catch((error) => {
				const errMsg = errorMessage(error);
				showToastMessage({
					type: "error",
					title: "Error",
					description: errMsg || "An error occurred",
				});
			});
		getSingleUserData(userId, token)
			.then((result) => {
				setLoading(false);
				setUserData(result);
				setThreshold(result?.userData?.hourlyEmailLimit);
			})
			.catch((error) => {
				setLoading(false);
				const errMsg = errorMessage(error);
				showToastMessage({
					type: "error",
					title: "Error",
					description: errMsg || "An error occurred",
				});
			});
		// eslint-disable-next-line
	}, []);

	const columns = [
		{
			title: "Date",
			dataIndex: "createdAt",
			key: "date1",
			sorter: (a, b) => a.date.length - b.date.length,
			sortDirections: ["descend"],
			width: 100,
			render: (text, record) => (
				<TextContent colour={Colour.textBlack2}>{text}</TextContent>
			),
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount1",
			width: 100,
			render: (text, record) => (
				<TextContent colour={Colour.textBlack2}>{text}</TextContent>
			),
		},
		{
			title: "Plan Type",
			dataIndex: "planType",
			key: "plantype1",
			width: 70,
			render: (text, record) => (
				<TextContent colour={Colour.textBlack2}>{text}</TextContent>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status1",
			width: 70,
			render: (text, record) => {
				const todaysDate = customDateChk.convert(Date.now());
				const currentStatus = todaysDate > record.expiresAt;

				return (
					<TextContent colour={Colour.textBlack2}>
						{currentStatus ? "Active" : "Expired"}
					</TextContent>
				);
			},
			// render: (text, record) => <TextContent colour={Colour.textBlack2}>{text}</TextContent>,
		},
	];

	return (
		<UsersPageContain>
			<div className="main-div">
				<div className="long">
					<div className="grid grid-4">
						<CardItem
							loading={loading}
							color={"#2eafa3"}
							title="Active Campaigns"
							count={userData?.activeCampaigns}
							icon={<MdCampaign style={{ fill: "#2eafa3", width: "1.2rem" }} />}
						/>
						<CardItem
							loading={loading}
							icon={
								<MdEmailRead style={{ fill: "#ff5b79", width: "1.2rem" }} />
							}
							color={"#FF5B79"}
							title="Total Emails Sent"
							count={userData?.totalDeliveredEmails}
						/>
						<CardItem
							loading={loading}
							icon={
								<MdDiversity style={{ fill: "#2383bd", width: "1.2rem" }} />
							}
							color={"#2383BD"}
							title="Recipients"
							count={userData?.totalRecipients}
						/>
						<CardItem
							loading={loading}
							icon={
								<MdEmailCredit style={{ fill: "#f8c200", width: "1.2rem" }} />
							}
							color={"#F8C200"}
							title="Email Credits"
							count={`${formatNumber(userData?.emailCredits)}`}
						/>
					</div>
					<div className="grid grid-4">
						<CardItem
							loading={loading}
							color={"#2eafa3"}
							title="Date of last delivered email"
							count={dayjs(userData?.dateOfLastDeliveredEmail).format('ddd MMM, Do yyyy')}
							icon={<MdCampaign style={{ fill: "#2eafa3", width: "1.2rem" }} />}
						/>
						<CardItem
							loading={loading}
							icon={
								<MdEmailRead style={{ fill: "#ff5b79", width: "1.2rem" }} />
							}
							color={"#FF5B79"}
							title="Total Emails Sent Today"
							count={userData?.mailSentToday}
						/>
						<CardItem
							loading={loading}
							icon={
								<MdDiversity style={{ fill: "#2383bd", width: "1.2rem" }} />
							}
							color={"#2383BD"}
							title="Recipients added today"
							count={userData?.recipientAddedToday}
						/>
						{/* <CardItem
							loading={loading}
							icon={
								<MdEmailCredit style={{ fill: "#f8c200", width: "1.2rem" }} />
							}
							color={"#F8C200"}
							title="Email Credits"
							count={`${formatNumber(userData?.emailCredits)}`}
						/> */}
					</div>
					<div className="name-box">
						<TextItem
							title={"Name"}
							subTitle={userData?.userData.name || userData?.userData.fullname}
							loading={loading}
						/>
						<TextItem
							title={"Email Address"}
							subTitle={userData?.userData.email}
							loading={loading}
						/>
						<TextItem
							title={"Date Joined"}
							subTitle={
								userData?.userData?.createdAt &&
								customDateChk.convert(userData?.userData?.createdAt)
								// : "01/01/2022"
							}
							loading={loading}
						/>
						<TextItem
							title={"Plan Type"}
							plantype={userData?.userData.userPlan.planType}
							loading={loading}
						/>
						<TextItem
							title={"Status"}
							status={userData?.userData.accountStatus.status}
							isBlocked={userData?.userData.isBlocked}
							loading={loading}
						/>
					</div>
				</div>

				<EmailThresholdForm
					setThreshold={setThreshold}
					threshold={threshold}
					token={token}
					userId={userId}
				/>
			</div>

			<BillingHistoryContain>
				<TextContent fontSize={16} fontWeight="500" colour={Colour.textBlack2}>
					Billing History
				</TextContent>
				<Table
					loading={userBillingHistory === null}
					columns={columns}
					data={userBillingHistory}
				/>
			</BillingHistoryContain>
		</UsersPageContain>
	);
};

export default User;

import styled from "styled-components";
import Colour from "../../utils/Colour";

export const AnalyticBoardContain = styled.div`
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: start;
	flex-wrap: wrap;
	border: 1px solid ${Colour.dripBlue};
	box-sizing: border-box;
	border-radius: 10px;
	padding: 1.5rem;
	position: relative;
	margin: ${({ singleUse }) => (singleUse ? "0 0 3rem 0" : "1.5rem 0 3rem 0")};
	& > div:first-child {
		margin-left: 0;
		border-left: 0;
		padding-left: 0;

		&::before {
			content: "";
		}
	}
	& > div:last-child {
		padding-right: 0;
	}
`;

export const AnalyticCardBox = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: fit-content;
	padding-left: 1.5rem;
	padding-right: 1.5rem;

	&::before {
		content: ".";
		width: 1px;
		position: absolute;
		border-left: 1px solid ${Colour.neutral};
		margin-left: -1.5rem;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
	}
`;
export const AnalyticCardDigits = styled.div`
	display: flex;
	align-items: center;
	width: fit-content;

	& > div {
		margin-left: 10px;
	}
`;

export const AnalyticCardBox2 = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: fit-content;
	padding-left: 1.5rem;
	padding-right: 1.5rem;

	& > span:first-child {
		margin-bottom: 1rem;
		background-color: ${({ plantype, status }) => plantype || status};
	}
	& > span:last-child {
		text-align: center;
	}
`;

import React from "react";

const BlockUserModal = ({ isBlocked, onCancel, onConfirm }) => {
  const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999, 
  };

  const modalStyle = {
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "20px",
    width: "400px",
  };

  const modalContentStyle = {
    textAlign: "center",
  };

  const modalButtonStyle = {
    marginTop: "20px",
  };

  const buttonStyle = {
    marginRight: "10px",
  };

   return (
    <div style={modalOverlayStyle}>
      <div style={modalStyle}>
        <div style={modalContentStyle}>
          <h2>{isBlocked ? "Unblock User" : "Block User"}</h2>
          <p>
            Are you sure you want to{" "}
            {isBlocked ? "unblock this user?" : "block this user?"}
          </p>
          <div style={modalButtonStyle}>
            <button style={buttonStyle} onClick={onCancel}>Cancel</button>
            <button style={buttonStyle} onClick={onConfirm}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockUserModal;

import { Table as AntTable } from "antd";

const Table = ({
	columns,
	data,
	onChange,
	scroll,
	rowClassName,
	canSelectRows,
	selectedRow,
	onSelectedRowChange,
	loading,
	pagination
}) => {
	return (
		<AntTable
			rowKey={(dat) => dat?.key || dat?._id}
			columns={columns}
			dataSource={data}
			onChange={onChange}
			scroll={scroll}
			rowClassName={rowClassName}
			rowSelection={
				canSelectRows
					? {
							selectedRowKeys: selectedRow,
							onChange: onSelectedRowChange,
					  }
					: undefined
			}
			loading={loading}
			
			pagination={pagination}
		/>
	);
};

export default Table;

import axios from "axios";
import Cookies from "js-cookie";
import { httpInstance } from "./httpService";
import { errMsgShared, errorMessage } from "../utils/getErrors";
const API = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
	baseURL: API,
	timeout: 10000,
});

export const login = (data) => {
	const httpReq = httpInstance.postMethod("/login", data);
	return httpReq;
};
export const loginClient = (data) => {
	const httpReq = httpInstance.postMethodForClient("/users/login", data);
	return httpReq;
};

export const generalUserMetrics = async (token) => {
	const httpReq = httpInstance.getMethodWithAuth("/usermetrics", token);
	return httpReq
		.then((response) => {
			return response.data.data;
		})
		.catch((error) => {
			errorMessage(error);
			errMsgShared(error);
		});
};

export const getAllUsers = async (token, search, sort) => {
	const httpReq = httpInstance.getMethodWithAuth(
		`/allusers?&email=${search}&sort=${sort}`,
		token
	);
	return httpReq
		.then((response) => {
			return response.data.data;
		})
		.catch((error) => {
			errMsgShared(error);
		});
};
export const setEmailThreshold = async (userId, token, data) => {
	const httpReq = httpInstance.putMethodWithAuth(
		`/hourly-email-limit?userId=${userId}`,
		data,
		token
	);
	return httpReq
		.then((response) => {
			return response.data.data;
		})
		.catch((error) => {
			errMsgShared(error);
		});
};
export const getAuthCode = async (token) => {
	const httpReq = httpInstance.getMethodWithAuth(
		`/payment/paystack/transactions/code`,
		token
	);
	return httpReq
		.then((response) => {
			return response.data.data;
		})
		.catch((error) => {
			errMsgShared(error);
		});
};
export const getComments = async (token) => {
	const httpReq = httpInstance.getMethodWithAuth("/comments", token);
	return httpReq
		.then((response) => {
			return response.data.data.comments;
		})
		.catch((error) => {
			errMsgShared(error);
		});
};

export const getQueued = async (token,limit,status,lastKey) => {
  const httpReq = httpInstance.getMethodWithAuth(`/queued-emails?limit=${limit}&status=${status}&lastKey=${lastKey}`, token);
  return httpReq
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errMsgShared(error);
    });
};

export const getSingleUserData = async (id, token) => {
	const httpReq = httpInstance.getMethodWithAuth(`/${id}/single-user`, token);
	return httpReq
		.then((response) => {
			return response.data.data;
		})
		.catch((error) => {
			errMsgShared(error);
		});
};
export const getWhitelistedUserData = async (token) => {
	const httpReq = httpInstance.getMethodWithAuth(`/get-whitelisted-users`, token);
	return httpReq
		.then((response) => {
			return response.data.data;
		})
		.catch((error) => {
			errMsgShared(error);
		});
};


export const getSingleUserBilling = async (id, token) => {
	const httpReq = httpInstance.getMethodWithAuth(
		`/${id}/user-billing-history`,
		token
	);
	return httpReq
		.then((response) => {
			return response.data.data;
		})
		.catch((error) => {
			errMsgShared(error);
		});
};

export const setAuthToken = (token) => {
	if (token) {
		axios.defaults.baseURL = API;
		axios.defaults.headers.common["x-access-token"] = token;
		// axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	} else {
		delete axios.defaults.headers.common["x-access-token"];
	}
};

export const clearUserData = (route = "/login", reroute = true) => {
	localStorage.removeItem("userDetails");
	Cookies.remove("token");
	reroute && window.location.replace(route);
};

const isTokenExpired = (token) => {
	const authToken = token.split(".");
	const expiry = new Date(JSON.parse(window.atob(authToken[1])).exp * 1000);
	const today = new Date();
	return today >= expiry;
};

export const handleBasicAuth = ({ cancelLoading }, { showMessage }) => {
	axios.defaults.baseURL = API;
	//   check if we have auths in the first place
	if (Cookies.get("token")) {
		// we have a user token
		const token = Cookies.get("token");
		isTokenExpired(token)
			? (() => {
					showMessage("Session Expired");
					clearUserData();
			  })()
			: setAuthToken(token);
		cancelLoading();
	} else {
		//no previous login/activity
		// showMessage("Please, login 😊");
		clearUserData("", false);
		cancelLoading();
	}
};

export const handleRouteAuthStatus = ({ cancelLoading }, { showMessage }) => {
	if (Cookies.get("token")) {
		// we have a user token
		isTokenExpired(Cookies.get("token")) &&
			(() => {
				showMessage("Session Expired");
				clearUserData();
			})();
		cancelLoading();
	} else {
		//no previous login/activity
		showMessage("Please, login 😊");
		clearUserData();
		cancelLoading();
	}
};

export const blockUser = async (token, userId) => {
	try {
		const response = await httpInstance.putMethodWithAuth(
			"/block-user",
			{ userId },
			token
		);
		return response.data;
	} catch (error) {
		throw new Error(
			error.response.data.message || "An error occurred while blocking the user"
		);
	}
};

export const unblockUser = async (token, userId) => {
	try {
		const response = await httpInstance.putMethodWithAuth(
			"/unblock-user",
			{ userId },
			token
		);
		return response.data;
	} catch (error) {
		throw new Error(
			error.response.data.message ||
				"An error occurred while unblocking the user"
		);
	}
};

export const getCreditCode = async (token) => {
	const httpReq = httpInstance.getMethodWithAuth(`/manual-credit-token`, token);
	return httpReq
		.then((response) => {
			return response.data.data;
		})
		.catch((error) => {
			errMsgShared(error);
		});
};
export const manualCredit = async (token, data) => {
	const httpReq = httpInstance.postMethodWithAuth(`/credit-user`, data, token);
	return httpReq
		.then((response) => {
			return response.data.data;
		})
		.catch((error) => {
			errMsgShared(error);
		});
};

export const whitelistUser = async (token, userId) => {
	try {
		const response = await httpInstance.putMethodWithAuth("/whitelist-user", { "userId": userId }, token);
		return response.data;
	} catch (error) {
		throw new Error(
			error.response.data.message || "An error occurred while whitelisting user"
		);
	}
};

export const unwhitelistUser = async (token, userId) => {
	try {
		const response = await httpInstance.putMethodWithAuth(
			"/unwhitelist-user",
			{ userId },
			token
		);
		return response.data;
	} catch (error) {
		throw new Error(
			error.response.data.message ||
				"An error occurred while unwhitelisting user"
		);
	}
};
import { LoadingOutlined } from "@ant-design/icons";

import Colour from "../utils/Colour";
import { setBgColour, setColour } from "../utils/setColour";
import Tag from "./Tag";
import TextContent from "./TextContent";
import {
	AnalyticBoardContain,
	AnalyticCardBox,
	AnalyticCardBox2,
	AnalyticCardDigits,
} from "./styled/AnayticCard.styled";
import { useCallback, useEffect } from "react";

const AnalyticCard = ({ title, subTitle, newNumber, color, option2, plantype, status }) => {
	// console.log(typeof subTitle + subTitle)
	return (
		<>
			{option2 ? (
				<AnalyticCardBox2>
					<TextContent fontSize={16} fontWeight="600">
						{title}
					</TextContent>
					{subTitle ? (
						<TextContent fontSize={16} fontWeight="500">
							{subTitle.toString()}
						</TextContent>
					) : plantype ? (
						<TextContent
							fontSize={16}
							fontWeight="500"
							plantype
							bg={setColour(plantype === "PREMIUM" ? "green" : plantype === "ADVANCED" ? "warn" : "sky")}
							colour={Colour.textWhite}
						>
							{plantype}
						</TextContent>
					) : status ? (
						<TextContent
							fontSize={16}
							fontWeight="500"
							status
							bg={setBgColour(status === "active" ? "green" : "red")}
							colour={setColour(status === "active" ? "green" : "red")}
						>
							• {`${status === "active" ? "Active" : "Inactive"}`}
						</TextContent>
					) : (
						<LoadingOutlined />
					)}
				</AnalyticCardBox2>
			) : (
				<AnalyticCardBox>
					<TextContent fontSize={18} fontWeight="500">
						{title}
					</TextContent>
					<AnalyticCardDigits>
						<TextContent fontSize={30} fontWeight="600">
							{
								((typeof subTitle === "string") ? subTitle :
							(JSON.stringify(subTitle)) )|| <LoadingOutlined />}
						</TextContent>
						{JSON.stringify(newNumber) && (
							<Tag color={setColour(color)} bg={setBgColour(color)} analytic>
								{JSON.stringify(newNumber) || <LoadingOutlined />}
								{/* <sup> */}
								<svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M0 5.03L0.47 5.5L4.33333 1.63667V3.83333H5L5 0.5H1.66667V1.16667H3.86333L0 5.03Z"
										fill={setColour(color)}
									/>
								</svg>
								{/* </sup> */}
							</Tag>
						)}
					</AnalyticCardDigits>
				</AnalyticCardBox>
			)}
		</>
	);
};

export default AnalyticCard;

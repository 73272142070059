import React, { useEffect,useState } from 'react';
import jsCookie from "js-cookie";
import { getQueued } from '../service/serviceInstance'
import {
    UsersPageContain,
    UsersTableContain,
} from "../components/styled/Users.styled";
import Tag from "../components/Tag";
import TextContent from "../components/TextContent";
import Table from "../components/Table";
import Colour from "../utils/Colour";
import dayjs from 'dayjs';
import { showToastMessage } from "../utils/Toast";
import { errorMessage } from "../utils/getErrors";
import { Select } from 'antd';

const ReportPage = ({ setRouteName }) => {
    const token = jsCookie.get("token");
    const [tableData, setTableData] = useState();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    // eslint-disable-next-line
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState({ id: "" });
    // eslint-disable-next-line
    const [limit, setLimit] = useState(100);
    // eslint-disable-next-line
  const [lastKey, setLastKey] = useState("");
  const [status, setStatus]  = useState('queued')
    
    useEffect(() => {
        setRouteName("Report Page");
        const getReportData = async () => {
            setLoading({ id: "fetchUsers" });
          await getQueued(token, limit,status, lastKey)
                .then((result) => {
                    setLoading({ id: "" });
                    setTableData(result?.data?.results);
                    setTotalPages(result?.data?.totalResults);
                })
                .catch((error) => {
                    const errMsg = errorMessage(error);
                    setLoading({ id: "" });
                    showToastMessage({
                        type: "error",
                        title: "Error",
                        description: errMsg || "An error occurred",
                    });
                });          
        }
        const handleSearch = setTimeout(getReportData, 2000);
        return () => clearTimeout(handleSearch);

    }, [search, setRouteName, lastKey, limit,status, token]);


  const handleChange = (value) => {
  setStatus(value);
};


    const columns = [

        {
            title: "Email",
            dataIndex: "UserEmail",
            key: "UserEmail",
        },
        {
            title: "User",
            dataIndex: "User",
            key: "User",
      },
        {
            title: "Title",
            dataIndex: "Title",
            key: "Title",
      },
        {
            title: "Campaign Id ",
            dataIndex: "CampaignId",
            key: "CampaignId",
      },
        {
            title: "Recipients",
            dataIndex: "Recipients",
            key: "Recipients",
        },
        {
            title: "Created At",
            dataIndex: "CreatedAt",
            key: "CreatedAt",
            sorter: {
                compare: (a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt),
               
    },  render: (data) => (
          <p>
                {dayjs(data).format('ddd MMM, D YYYY h:mm A')}
          </p>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
        },
        {
            title: "Queued mails",
            dataIndex: "QueuedAt",
            key: "QueuedAt",
            sorter: {
      compare: (a, b) => a.QueuedAt - b.QueuedAt,
    },
      sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'ascend',
       render: (record) => (
          <p>
            {dayjs(record).format('ddd MMM, D YYYY, h:mm A')}
          </p>
      ),
        },
        {
            title: "Updated At",
            dataIndex: "UpdatedAt",
            key: "UpdatedAt",
            sorter: {
      compare: (a, b) => a.UpdatedAt - b.UpdatedAt,
    },
      sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'ascend',
       render: (record) => (
         <p>
           {dayjs(record).format('ddd MMM, D YYYY')}
          </p>
      ),
      },
        {
            title: "Status",
            dataIndex: "Status",
            key: "Status",
        render: (text, record) => {
        return (
          <Tag
            color={record?.Status||text === "processing"?Colour.textBlack2:Colour.textWhite}
            bg={
              record?.Status === "delivered"
                ? Colour.success
                : record?.Status === "failed"
                ? Colour.warning
                : record?.Status === "processing"
                            ? Colour.skyBlue
                            : '	#fdfa72'
            }
            status
          >
            <TextContent>{record?.Status||text}</TextContent>
          </Tag>
        );
      },
        },
    
    ];

   



    return (
        <div style={{marginTop:'3rem'}}>
        <UsersPageContain>
            <UsersTableContain>
                <div className="flexed">
                    <div>
                    </div>
            </div>
            <div style={{display:'flex', marginBottom:'1rem', alignItems:'center'}}>
              <p style={{marginRight:'.5rem',fontWeight:'700'}}>Set report status:</p>
            
            <Select
      defaultValue="queued"
      style={{
        width: 120,
      }}
      onChange={handleChange}
      options={[
        {
          value: 'queued',
          label: 'Queued',
        },
        {
          value: 'delivered',
          label: 'Delivered',
        },
        {
          value: 'processing',
          label: 'Processing',
        },
        {
          value: 'failed',
          label: 'Failed',
        }
      ]}
              />
              </div>
                <Table
                      key={tableData?.Id}
                    columns={columns}
                     data={tableData}
                    loading={loading.id === "fetchUsers"}
                    scroll={{ y: 350 }}
                  pagination={{
                    onChange: (page) => {
                      setPage(page);
                    },
                    total: totalPages,
                    pageSize: 50,
                    current: page,
                    showSizeChanger: false,
                    showLessItems: true,
                  }}
                />
            </UsersTableContain>
            </UsersPageContain>
            </div>
    )
}

export default ReportPage
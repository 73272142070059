import styled from "styled-components";
import Colour from "../../utils/Colour";

export const CommentPageContain = styled.div`
	.unread-comment {
		background-color: ${Colour.unReadBg};
	}

	& .ant-table-body {
		/* content: "@";
		display: block;
		line-height: 10px;
		visibility: hidden; 
		/* text-indent: -99999px; */

		margin-top: 0.7rem;
	}
`;

export const CommentSectionContainer = styled.section`
	padding: 0.4rem 0.7rem;
	border: 1px solid ${Colour.neutral};
	border-radius: 4px;
	margin: 1.3rem;
	max-height: 220px;
	overflow-y: scroll;
	> span:first-child {
		line-height: 20px;
	}
`;

import Colour from "./Colour";

export const setColour = (color) => {
	const colorKey = {
		blue: Colour.dripBlue,
		green: Colour.success,
		red: Colour.alert,
		warn: Colour.warning,
		sky: Colour.skyBlue,
	};
	return colorKey[color];
};

export const setBgColour = (bgColor) => {
	const colorKey = {
		blue: Colour.bgBlue,
		green: Colour.bgSuccess,
		red: Colour.bgAlert,
	};
	return colorKey[bgColor];
};

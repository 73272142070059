import React from "react";
import { setColour } from "../utils/setColour";
import { Spin } from "antd";

export const TextItem = ({ title, subTitle, plantype, status, isBlocked, loading }) => {
	return (
		<div className="group-item">
			<div className="title">{title}</div>
			{loading && <Spin />}
			{subTitle && <div className="sub">{subTitle}</div>}
			{plantype && (
				<div
					className="plan-type"
					style={{
						backgroundColor: setColour(
							plantype === "PREMIUM"
								? "green"
								: plantype === "ADVANCED"
								? "warn"
								: "sky"
						),
					}}
				>
					{plantype}
				</div>
			)}

			{isBlocked === true ?  (
				<div
					className="status"
					style={{
						color: setColour("red"),
					}}
				>
					{`Blocked User`}
				</div>
			) : status && (
				<div
					className="status"
					style={{
						color: setColour(status === "active" ? "green" : "red"),
					}}
				>
					{`${status === "active" ? "Active" : "Inactive"}`}
				</div>

			)
			}
		</div>
	);
};

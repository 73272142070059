import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalyticCard from "../components/AnalyticCard";
import { AnalyticBoardContain } from "../components/styled/AnayticCard.styled";
import {
  UsersPageContain,
  UsersTableContain,
} from "../components/styled/Users.styled";
// import { allUsersData } from "../utils/mockedUsersData";
import Table from "../components/Table";
// import TableTools from "../components/TableTools";
import Tag from "../components/Tag";
import TextContent from "../components/TextContent";
import Colour from "../utils/Colour";
import { generalUserMetrics, getAllUsers, blockUser, unblockUser, whitelistUser, unwhitelistUser} from "../service/serviceInstance";
import { showToastMessage } from "../utils/Toast";
import { errorMessage } from "../utils/getErrors";
import jsCookie from "js-cookie";
import { truncateString } from "../utils/generics";
import { customDateChk } from "../utils/useDate";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { Input,
 Select, Button, Checkbox
 } from "antd";
 import BlockUserModal from "../components/BlockUserModal";
import WhitelistUserModal from "../components/WhitelistUserModal";
const { Search } = Input;

const Users = ({ setRouteName }) => {
  // const [filterValue, setFilterValue] = useState(' ');
  const [fetchedData, setFetchedData] = useState({});
  const [allUsersData, setAllUsersData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortedInfo, setSortedInfo] = useState({});
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState('totalRecipients')
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState({ id: "" });
  const [sorter, setSorter] = useState("asc");
  const [blockedUsers, setBlockedUsers] = useState(0);
  const [isModalActive, setModalActive] = useState(false);
  const [isWhitelistedActive, setWhitelistedActive] = useState(false);
  const [modalUserId, setModalUserId] = useState(null);
  const [modalIsBlocked, setModalIsBlocked] = useState(null);
  const [modalIsWhitelisted, setModalIsWhitelisted] = useState(null);
  const [whitelistedUsers, setWhitelistedUsers] = useState(0);
  

  const navigate = useNavigate();
  const token = jsCookie.get("token");
  useEffect(() => {
    setRouteName("Users");

    // setTimeout(() => {
    generalUserMetrics(token)
      .then((result) => setFetchedData(result))
      .catch((error) => {
        const errMsg = errorMessage(error);
        showToastMessage({
          type: "error",
          title: "Error",
          description: errMsg || "An error occurred",
        });
      });
    // setFetchedData(newAnalyticData);

    // }, 2000);
    // eslint-disable-next-line
  }, []);


  const headers = [
    { displayLabel: "Enable/Disable", key: "enabled" },
    { displayLabel: "Name", key: "name" },
    { displayLabel: "Email Address", key: "email" },
    { displayLabel: "Plan Type", key: "userPlan?.planType" },
    { displayLabel: "Status", key: "accountStatus?.status" },
    { displayLabel: "Joined On", key: "createdAt" },
    { displayLabel: "Phone Number", key: "phoneNumber" },
    { displayLabel: "Email Credits", key: "emailCredits" },
    { displayLabel: "All Contact Size", key: "totalContacts" },
    { displayLabel: "All Recipient Size", key: "totalRecipients" },
    { displayLabel: "Transaction Count", key: "totalTransactions" },
    { displayLabel: "Last Login Date", key: "lastLoginDate" },
    { displayLabel: "Last Email Sent Date", key: "dateOfLastDeliveredEmail" },
    { displayLabel: "Lead Source", key: "" },
  ];

  // const exportLimit = 100;
  const csvConfig = mkConfig({
    filename: "All contacts",
    columnHeaders: headers,
  });

  let contacts = [];
  const fetchAllContactsAndDownload = async () => {
    setLoading({ id: "export" });
    // const getTotalPages = Math.ceil(totalPages / exportLimit);
    // const iterationTimes = Array.from(
    //   { length: getTotalPages },
    //   (_, index) => index + 1
    // );
    try {
      // for (const item of iterationTimes) {
      //   const result = await getAllUsers(token, item, exportLimit);
      //   contacts.push(...result?.modifiedAllUsers);
      // }
      allUsersData.length > 0 && contacts.push(...allUsersData);
      const csv = generateCsv(csvConfig)(contacts);
      download(csvConfig)(csv);
      setLoading({ id: "" });
    } catch (error) {
      setLoading({ id: "" });
      const errMsg = errorMessage(error);
      setLoading({ id: "" });
      showToastMessage({
        type: "error",
        title: "Error",
        description: errMsg || "An erxror occurred",
      });
    }
  };

  const customDateSorter = (a, b) => {
  // Handle empty values
  if (!a.lastLoginDate && !b.lastLoginDate) {
    return 0;
  }
  if (!a.lastLoginDate) {
    return -1;
  }
  if (!b.lastLoginDate) {
    return 1;
  }
  const dateA = new Date(a.lastLoginDate);
  const dateB = new Date(b.lastLoginDate);
  return dateA - dateB;
};



 const handleEnableDisable = (userId, isBlocked) => {
    setModalUserId(userId); // take in selected user and pass to onConfirmModal function
    setModalIsBlocked(isBlocked); // pass status blocked status to decipher which message the modal shows
    setModalActive(true); 
  };

  const onCancelModal = () => {
    setModalActive(false);
  };

  const onConfirmModal = async () => {
    setModalActive(false); 
    try {
      const userData = allUsersData.find((user) => user._id === modalUserId);
      const isBlocked = userData?.isBlocked || false;

      if (isBlocked) {
        await unblockUser(token, modalUserId);
        showToastMessage({
          type: "success",
          title: "Enable User Account",
          description: userData.message || "Successfully Unblocked User",
        });
      } else {
        await blockUser(token, modalUserId);
        showToastMessage({
          type: "warning",
          title: "Disable User Account",
          description: userData.message || "Successfully blocked User",
        });
      }

      const updatedList = allUsersData.map((user) =>
        user._id === modalUserId ? { ...user, isBlocked: !isBlocked } : user
      );
      setAllUsersData(updatedList);

      const newBlockedUsersCount = isBlocked
        ? blockedUsers - 1
        : blockedUsers + 1;
      setBlockedUsers(newBlockedUsersCount);
    } catch (error) {
      showToastMessage({
        type: "error",
        title: "Error",
        description:
          error.message || "An error occurred while updating user status",
      });
    }
  };

  const handleWhitelistedUser =(userId, isWhitelisted) => {
    setModalUserId(userId); 
    setModalIsWhitelisted(isWhitelisted);
    setWhitelistedActive(true);
  }

  const onCancelWhiteModal = () => {
    setWhitelistedActive(false);
  };

  const onConfirmWhitelistModal = async () => {
    setWhitelistedActive(false); 
    try {
      const userData = allUsersData.find((user) => user._id === modalUserId);
      const whiteListed = userData?.whiteListed || false;

      if (whiteListed) {
        await unwhitelistUser(token,userData._id );
        showToastMessage({
          type: "success",
          title: "Enable User Account",
          description: userData.message || "Successfully unwhitelisted User",
        });
      } else {
        await whitelistUser(token, userData._id);
        showToastMessage({
          type: "warning",
          title: "Disable User Account",
          description: userData.message || "Successfully whitelisted User",
        });
      }

      const updatedList = allUsersData.map((user) =>  user._id === modalUserId ? { ...user, whiteListed: !whiteListed } : user);
      setAllUsersData(updatedList);

      const newWhitelistedUsersCount = whiteListed
        ? whitelistedUsers - 1
        : whitelistedUsers + 1;
      setWhitelistedUsers(newWhitelistedUsersCount);
    } catch (error) {
      showToastMessage({
        type: "error",
        title: "Error",
        description:
          error.message || "An error occurred while updating user status",
      });
    }
  };

  const columns = [
  {
      title: "Whitelist user",
      dataIndex: "whiteListed",
      key: "whiteListed",
      render: (text, record) => (
        <Checkbox
          checked={record?.whiteListed} 
          onChange={(e) => handleWhitelistedUser(record._id, record?.whiteListed)}
        />
      ),
    },
     {
      title: "Enable/Disable",
      dataIndex: "isBlocked",
      key: "isBlocked",
      render: (isBlocked, record) => (
        <Checkbox
          checked={record?.isBlocked} 
          onChange={(e) => handleEnableDisable(record._id, record?.isBlocked)} //pass selected user along with the isBlocked status (true / false)
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (text, record) => (
        <TextContent
          colour={Colour.textBlack2}
          onClick={() => navigate(`/users/${record._id}`)}
        >
          {text}
        </TextContent>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      //   sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      render: (text, record) => (
        <TextContent
          title={text}
          colour={Colour.textBlack2}
          onClick={() => navigate(`/users/${record._id}`)}
        >
          {truncateString(text, 20)}
        </TextContent>
      ),
    },
    {
      title: "Plan Type",
      dataIndex: "userPlan",
      key: "userPlan",
      sortOrder: sortedInfo.columnKey === 'userPlan' ? sortedInfo.order : null,
      render: (text, record) => {
        return (
          <Tag
            color={Colour.textWhite}
            bg={
              record?.userPlan?.planType === "Premium"
                ? Colour.success
                : record?.userPlan?.planType === "Advanced"
                ? Colour.warning
                : Colour.skyBlue
            }
            plantype
          >
            <TextContent>{record?.userPlan?.planType}</TextContent>
          </Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "accountStatus",
      key: "accountStatus",
      sortOrder: sortedInfo.columnKey === 'accountStatus' ? sortedInfo.order : null,
      render: (text, record) => (
        <TextContent
          style={{ textTransform: "capitalize" }}
          colour={Colour.textBlack2}
        >
          {record.isBlocked ? "blocked" : text.status}
        </TextContent>
      ),
    },
    {
      title: "Joined On",
      dataIndex: "createdAt",
      key: "_id",
      //   sortDirections: ["descend"],
      // width: 100,
      //   sorter: (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt),
      sorter: {
      compare: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: 'ascend',
      sortOrder: sortedInfo.columnKey === '_id' ? sortedInfo.order : null,
      render: (text, record) => (
        <TextContent onClick={()=>setSortField('createdAt')} colour={Colour.textBlack2}>
          {record?.createdAt
            ? customDateChk.convert(record?.createdAt)
            : "01/01/2022"}
        </TextContent>
      ),
      // render: (text, record) => <TextContent colour={Colour.textBlack2}>{text}</TextContent>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      sortOrder: sortedInfo.columnKey === 'phoneNumber' ? sortedInfo.order : null,
      render: ( text, record) => (
        <TextContent
          style={{ textTransform: "capitalize" }}
          colour={Colour.textBlack2}
        >
          {record?.phoneNumber}
        </TextContent>
      ),
    },
    {
      title: "Email Credits",
      dataIndex: "emailCredits",
      key: "emailCredits",
      sorter: {
      compare: (a, b) => a.emailCredits - b.emailCredits,
      multiple: 1,
    },
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortedInfo.columnKey === 'emailCredits' ? sortedInfo.order : null,
      render: (text, record) => (
        <TextContent
          style={{ textTransform: "capitalize" }}
          colour={Colour.textBlack2}
          onClick={()=>setSortField('emailCredits')}
        >
          {record?.emailCredits}
        </TextContent>
      ),
    },
    {
      title: "All Contact Size",
      dataIndex: "totalContacts",
      key: "totalContacts",
      sorter: {
      compare: (a, b) => a.totalContacts - b.totalContacts,
      multiple: 1,
    },
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortedInfo.columnKey === 'totalContacts' ? sortedInfo.order : null,
      render: (text, record) => (
        <TextContent
          style={{ textTransform: "capitalize" }}
          colour={Colour.textBlack2}
          onClick={()=>setSortField('totalContacts')}
        >
          {record?.totalContacts}
        </TextContent>
      ),
    },
    {
      title: "All Recipient Size",
      dataIndex: "totalRecipients",
      sorter: {
      compare: (a, b) => a.totalRecipients - b.totalRecipients,
      multiple: 2,
    },
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortedInfo.columnKey === 'totalRecipients' ? sortedInfo.order : null,
      key: "totalRecipients",
      render: (text, record) => (
        <TextContent
          style={{ textTransform: "capitalize" }}
          colour={Colour.textBlack2}
          onClick={()=>setSortField('totalRecipients')}
        >
          {record?.totalRecipients}
        </TextContent>
      ),
    },
    {
      title: "Transaction counts",
      dataIndex: "totalTransactions",
      key: "totalTransactions",
      sorter: {
      compare: (a, b) => a.totalTransactions - b.totalTransactions,
      multiple: 3,
    },
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortedInfo.columnKey === 'totalTransactions' ? sortedInfo.order : null,
      render: (text, record) => (
        <TextContent
          style={{ textTransform: "capitalize" }}
          colour={Colour.textBlack2}
          onClick={()=>setSortField('totalTransactions')}
        >
          {record?.totalTransactions}
        </TextContent>
      ),
    },
    {
      title: "Last Transaction date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      sorter: {
      compare: (a, b) => new Date(a.paymentDate) - new Date(b.paymentDate),
      multiple: 3,
    },
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortedInfo.columnKey === 'paymentDate' ? sortedInfo.order : null,
      render: (text, record) => (
        <TextContent
          style={{ textTransform: "capitalize" }}
          colour={Colour.textBlack2}
          onClick={()=>setSortField('paymentDate')}
        >
            {record?.paymentDate
            ? customDateChk.convert(record?.paymentDate)
            : ""}
        </TextContent>
      ),
    },
    {
      title: "Transaction type",
      dataIndex: "planType",
      key: "planType",
      sorter: {
      compare: (a, b) => a.planType - b.planType,
      multiple: 3,
    },
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortedInfo.columnKey === 'planType' ? sortedInfo.order : null,
      render: (text, record) => (
        <TextContent
          style={{ textTransform: "capitalize" }}
          colour={Colour.textBlack2}
          onClick={()=>setSortField('planType')}
        >
          {record?.planType}
        </TextContent>
      ),
    },
    {
      title: "Last login",
      dataIndex: "lastLoginDate",
      key: "lastLoginDate",
     sorter: (a, b) => customDateSorter(a, b),
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortedInfo.columnKey === 'lastLoginDate' ? sortedInfo.order : null,
      render: (text, record) => (
        <TextContent
          style={{ textTransform: "capitalize" }}
          colour={Colour.textBlack2}
          onClick={()=>setSortField('lastLoginDate')}
        >
          {record?.lastLoginDate
            ? customDateChk.convert(record?.lastLoginDate || record?.updatedAt)
            : ""}
        </TextContent>
      ),
    },
    {
      title: "Last Email Sent Date",
      dataIndex: "dateOfLastDeliveredEmail",
      key: "dateOfLastDeliveredEmail",
      sorter: {
      compare: (a, b) => new Date(a.dateOfLastDeliveredEmail) - new Date(b.dateOfLastDeliveredEmail),
      multiple: 5,
    },
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortedInfo.columnKey === 'dateOfLastDeliveredEmail' ? sortedInfo.order : null,
      render: (text, record) => (
        <TextContent
          style={{ textTransform: "capitalize" }}
          colour={Colour.textBlack2}
          onClick={()=>setSortField('dateOfLastDeliveredEmail')}
        >
          {record?.dateOfLastDeliveredEmail
            ? customDateChk.convert(record?.dateOfLastDeliveredEmail)
            : ""}
        </TextContent>
      ),
    },
    {
      title: "Lead Source",
      dataIndex: "leadSource",
      key: "leadSource",
      sortOrder: sortedInfo.columnKey === 'leadSource' ? sortedInfo.order : null,
      render: (text, record) => (
        <TextContent
          style={{ textTransform: "capitalize" }}
          colour={Colour.textBlack2}
        ></TextContent>
      ),
    },
   ];

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

const handleTableChange = (pagination, filters, sorter,extra) => {
   const { columnKey, order } = sorter;
  
  setSortedInfo(sorter);
  
  // Update the sorter state based on the column key and order
  if (order) {
    // If there is an order, set the sorter state with the column key and order
    // setSorter(`${order === 'descend' ? 'desc' : 'asc'}_${columnKey}`);
    setSorter(`${columnKey}`);
  } else {
    // If there is no order, clear the sorter state
    setSorter('');
  }
  };


  // eslint-disable-next-line
  const filteredData = sorter
  ? allUsersData?.filter((item) => {
      switch (sorter) {
        case 'emailCredits':
          return item.emailCredits ; 
        case 'totalContacts':
          return sorter === item.totalContacts;
        case 'totalTransactions':
          return sorter === item.totalTransactions; 
        case 'descend':
          return sorter==='des';
        case 'ascend':
          return sorter==='asc'
        default:
          return sorter==='asc'; 
      }
    })
  : allUsersData;

  // eslint-disable-next-line
    const { Option } = Select;

  // eslint-disable-next-line
  const handleFilterChange = value => {
    //setFilterValue(value);
    setSorter(value)
  };

  const clearAll = () => {
    setSortedInfo({});
  };
//  allUsersData.map((a) => console.log( "users ", a) )
     
 useEffect(() => {
  const getUsersFunction = async () => {
    setLoading({ id: "fetchUsers" });
    await getAllUsers(token, search, sortField) 
      .then((result) => {
        setLoading({ id: "" });
        setAllUsersData(result?.modifiedAllUsers);
        setBlockedUsers(result?.modifiedAllUsers?.filter(user => user.isBlocked).length);
        setWhitelistedUsers(result?.modifiedAllUsers?.filter(user => user.whiteListed).length);
        setTotalPages(result?.totalUsers);

      })
      .catch((error) => {
        const errMsg = errorMessage(error);
        setLoading({ id: "" });
        showToastMessage({
          type: "error",
          title: "Error",
          description: errMsg || "An error occurred",
        });
      });
   };
   
  const handleSearch = setTimeout(getUsersFunction, 2000);

  return () => clearTimeout(handleSearch);

}, [search, sortField,token]);  // the compiler is going to yell, but the general idea is we don't want to run this effect on every render, only when the search value changes. 
// }, [search, sorter, sortField, token]); // if we want to go this route then a cache should be implemented to save the data and avoid multiple api calls


  return (
    <UsersPageContain>

       {isModalActive && (
        <BlockUserModal
          isBlocked={modalIsBlocked}
          onCancel={onCancelModal}
          onConfirm={onConfirmModal}
        />
      )}
      {
        isWhitelistedActive && (
          <WhitelistUserModal
          onCancel={onCancelWhiteModal}
          onConfirm={onConfirmWhitelistModal}
          isWhitelisted={modalIsWhitelisted}
          />
        )
      }

      <TextContent fontSize={24} fontWeight="500">
        Users
      </TextContent>
      <AnalyticBoardContain>
        <AnalyticCard
          subTitle={fetchedData?.allUsersCount}
          newNumber={fetchedData?.usersJoinedToday}
          title="Total Users"
          color="blue"
        />
        <AnalyticCard
          subTitle={fetchedData?.allActiveUsersCount}
          newNumber={fetchedData?.usersActiveToday}
          title="Active"
          color="green"
        />
        <AnalyticCard
          subTitle={fetchedData?.inactiveUserCount}
          newNumber={fetchedData?.inactiveUserCountToday}
          title="Inactive"
          color="red"
        />
        <AnalyticCard
          subTitle={fetchedData?.Advanced}
          // newNumber={fetchedData["Advanced Pro"]}
          title="Advance Plan"
          color="blue"
        />
        <AnalyticCard
          subTitle={fetchedData?.Premium}
          // newNumber={fetchedData["Premium Pro"]}
          title="Premium Plan"
          color="blue"
        />
        <AnalyticCard
          subTitle={blockedUsers}
          title="Blocked Users"
          color="blue"
        />
        <AnalyticCard
          subTitle={whitelistedUsers}
          title="Whitelisted Users"
          color="blue"
        />
      </AnalyticBoardContain>
      <UsersTableContain>
        <div className="flexed">
          {/* <TableTools
            onSearch={setSearch}
            data={allUsersData}
            setSearchedData={setSearchedData}
            searchFocus={["email"]}
            searchPlaceholder="Search by email"
            searchValue={search}
            canSearch
          /> */}

          <div>

          <Search
            placeholder="Search by email"
            loading={loading.id === "searching"}
            enterButton
            className="search-input"
            // onSearch={onSearch}
            value={search}
            onChange={handleChange}
        // onKeyDown={handleSearch}
            allowClear
          />
          <Button onClick={clearAll}>Clear sort</Button>
         </div>
          <div onClick={() => fetchAllContactsAndDownload()} className="export">
            {loading.id === "export" ? "Exporting..." : "Export"}
          </div>
        </div>
        <Table
          key={allUsersData?._id}
          columns={columns}
          data={allUsersData}
          loading={loading.id === "fetchUsers"}
          scroll={{ y: 350 }}
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            total: totalPages,
            pageSize: 50,
            current: page,
            showSizeChanger: false,
            showLessItems: true,
          }}
          onChange={handleTableChange}
          rowClassName={(record) => (record.isBlocked && "disabled-row" )}
          onRow={(record) => ({
            onClick: () => {
              if (!record.isBlocked) {
                navigate(`/users/${record._id}`);
              }
            },
          })}
        />
      </UsersTableContain>
    </UsersPageContain>
  );
};

export default Users;
const Colour = {
	textWhite: "#ffffff",
	textBlack: "#000000",
	textBlack2: "#1C1C1C",
	fadeBlack: "#555555",
	neutral: "#E3E3E3",
	neutral2: "#8E8E8E",
	textGreyrey: "#717171",
	textGreyrey2: "#5D7285",
	bgBlue: "#F2F3FE",
	dripBlue: "#273DF2",
	skyBlue: "#2097F6",
	unReadBg: "rgba(39, 61, 242, 0.05)",
	success: "#37B34A",
	bgSuccess: "#F5FBF6",
	warning: "#FFB82E",
	alert: "#EC615B",
	bgAlert: "#FEF7F7",
	bgGrey: "#F7F7F7",
};

export default Colour;

import styled from "styled-components";
import Colour from "../../utils/Colour";

export const LoginContainer = styled.div`
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	& > div {
		width: 100%;
		max-width: 513px;
		text-align: center;

		& > section {
			padding: 2rem;
			background: #ffffff;
			box-shadow: 0px 4px 20px rgba(39, 61, 242, 0.05);
			border-radius: 10px;

			& > div {
				span {
					display: block;
					margin: 1rem auto;
				}
				span:first-child {
					font-weight: 700;
					font-size: 36px;
					line-height: 44px;
					text-transform: capitalize;
				}
				span:last-child {
					font-weight: 500;
					font-size: 16px;
					line-height: 19px;
				}
			}
		}
	}
`;

import React from "react";
import shadeColor from "../utils/shadeColor";
import { Spin } from "antd";

export const CardItem = ({ icon, color, count, title, loading }) => {
	return (
		<div className="card-item">
			<div className="flex">
				<div className="block">
					{loading ? <Spin /> : <p className="main-text">{count}</p>}
					<p className="sub-text">{title}</p>
				</div>
				<div
					className="icon-box"
					style={{ backgroundColor: shadeColor(color, 0.1) }}
				>
					{icon}
				</div>
			</div>
		</div>
	);
};

import { Link, useLocation, useNavigate } from "react-router-dom";
import {
	commentIc,
	dashboardIc,
	logoutIc,
	maildripIc,
	notifyIc,
	profilePicDum,
	usersIc,
	reportIc
} from "../utils/importIcons";
import { routes } from "../utils/routes";
import { PageContain } from "./styled/Global.styled";
import { useEffect, useState } from "react";
import jsCookie from "js-cookie";
import { BackbuttonIcon } from "../asset/Backbutton";
import { getComments, generalUserMetrics } from "../service/serviceInstance";
import {
	LayoutContainer,
	LogoutContainer,
	MailDripLogo,
	MailDripLogoContain,
	MainContentContainer,
	MainContentHeader,
	MainContentHeaderContain,
	ProfileSection,
	SideBarContainer,
	SideBarContent,
	SideBarNavItem,
	SideBarNavLink,
} from "./styled/Layout.styled";
import { showToastMessage } from "../utils/Toast";
import { clearUserData } from "../service/serviceInstance";

const SideBarNavigation = ({ to = {}, isActive, src, title = "", count }) => (
	<Link to={to}>
		<SideBarNavItem isActive={isActive} count={count} title={title}>
			<div>
				<img src={src} alt={title} />
				<span>{title}</span>
			</div>
			{count && <span>{count}</span>}
		</SideBarNavItem>
	</Link>
);

const Layout = ({ children, routeName }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const locationName = location.pathname.split("/")[1];
	const [usersCount, setUserCount]  = useState()
	const [commentCount, setCommentCount] = useState();
  const [containMultiple, setContainMultiple] = useState(false);
const token = jsCookie.get("token");
	useEffect(() => {
		const accessMetricData = async ()=>{
			const user = await generalUserMetrics(token)
			const comments = await 	getComments(token)
			setUserCount(user?.allUsersCount)
		   setCommentCount(comments?.length)
		}
		accessMetricData()
	
	}, [token])


  useEffect(() => {
    const currentUrl = location?.pathname;
    const containsMultipleSlashes = currentUrl.match(/\//g).length;
	  if (containsMultipleSlashes >= 2) {
      setContainMultiple(true);
    } else {
      setContainMultiple(false);
    }
  }, [location]);
	
	const handleLogout = () => {
		showToastMessage({
			type: "warning",
			title: "Logged out",
			description: "",
		});
		clearUserData("", false);
		navigate("/login");
	};
	return (
		<LayoutContainer>
			<SideBarContainer>
				<MailDripLogoContain>
					<MailDripLogo src="/asset/icons/maildrip-logo.svg" alt="" />
					<span>Maildrip</span>
				</MailDripLogoContain>
				<SideBarContent>
					<SideBarNavLink>
						<SideBarNavigation
							src={dashboardIc}
							to={routes.dashboard}
							title="Dashboard"
							isActive={locationName === ""}
						/>
						<SideBarNavigation
							src={usersIc}
							to={routes.users}
							title="Users"
							count={usersCount}
							isActive={locationName === "users"}
						/>
						<SideBarNavigation
							src={commentIc}
							to={routes.comments}
							title="Comment"
							count={commentCount}
							isActive={locationName === "comments"}
						/>
						<SideBarNavigation
							src={reportIc}
							to={routes.reportPage}
							title="Report Page"
							isActive={locationName === "report-page"}
       />
                <SideBarNavigation
							src={maildripIc}
							to={routes.manualCredit}
							title="Manual Credit"
							isActive={locationName === "manual-credit"}
						/>
					</SideBarNavLink>
					<LogoutContainer>
						<img src={logoutIc} alt="" onClick={() => {}} />
						<span onClick={handleLogout}>Logout</span>
					</LogoutContainer>
				</SideBarContent>
			</SideBarContainer>
			<MainContentContainer>
				<MainContentHeaderContain>
					{containMultiple ? (
						<div style={{display:'flex',alignItems:'center', cursor:'pointer'}} onClick={() => navigate(-1)}>
							<BackbuttonIcon className="back-button-icon" /> &nbsp;
						<MainContentHeader > {routeName}</MainContentHeader>
						</div>)
						:
						<MainContentHeader>{routeName}</MainContentHeader>
						}
					<ProfileSection>
						<img src={notifyIc} alt="" />
						<img className="profile-picture" src={profilePicDum} alt="" />
					</ProfileSection>
				</MainContentHeaderContain>
				<PageContain>{children}</PageContain>
			</MainContentContainer>
		</LayoutContainer>
	);
};

export default Layout;

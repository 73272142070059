import styled from "styled-components";
import Colour from "../../utils/Colour";

export const Text = styled.span`
	font-size: ${({ fontSize }) => fontSize || 14}px;
	font-weight: ${({ fontWeight }) => fontWeight};
	color: ${({ colour }) => colour || Colour.BlackText};
	background: ${({ bg }) => bg || "transperent"};
	text-align: ${({ textAlign }) => textAlign};
	display: ${({ display }) => display};
	padding: ${({ plantype, status }) => (plantype ? "0.2rem 0.4rem" : status && "6px 16px")};
	border-radius: ${({ status }) => status && "18px"};
	${({ onClick }) => (onClick ? "cursor: pointer;" : "")};
`;

export const UseButton = styled.button`
	color: ${({ color }) => color || Colour.textWhite};
	background-color: ${({ bgColor }) => bgColor || Colour.dripBlue};
	border-radius: 4px;
	padding: 8px 26px;
	outline: none;
	border: none;
	cursor: pointer;
	display: ${({ display }) => display || "block"};
	margin: ${({ margin }) => margin || "0 auto"};

	&:hover {
		transition: 0.2s ease-in-out;
		filter: brightness(90%);
		${"" /* background-color: ${({ bgColor }) => `linear-gradient(${bgColor},0 0)` || Colour.dripBlue}; */}
	}
`;

export const Usetag = styled.div`
	width: fit-content;
	padding: ${({ analytic }) => (analytic ? "3px 11px" : "6px 16px")};
	border-radius: ${({ analytic, plantype }) => (analytic ? "12px" : plantype ? "2px" : "18px")};
	font-size: ${({ plantype }) => plantype && "12px"};
	font-weight: 500;
	color: ${({ color }) => color};
	background-color: ${({ bg }) => bg};

	svg {
		margin-left: 4px;
		vertical-align: middle;
	}
`;

export const PageContain = styled.div`
	padding: 1.5rem 1.5rem 0 1.5rem;
`;

import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { errorMessage } from "../utils/getErrors";
import { getCreditCode, manualCredit } from "../service/serviceInstance";
import jsCookie from "js-cookie";
import { showToastMessage } from "../utils/Toast";

const ManualCredit = ({ setRouteName }) => {
	const token = jsCookie.get("token");
	const [formData, setFormData] = useState({
		email: "",
		credit: "",
		code: "",
	});
	const [loading, setLoading] = useState({ id: "" });
	const creditUser = async () => {
		setLoading({ id: "credit" });
		try {
			const result = await manualCredit(token, {
				email: formData.email,
				quantity: formData.credit,
				authorizationCode: formData.code,
			});
			showToastMessage({
				type: "success",
				title: "Success",
				description: result?.message,
			});
			setLoading({ id: "" });
			setFormData({
				email: "",
				credit: "",
				code: "",
			});
		} catch (error) {
			setLoading({ id: "" });
			const errMsg = errorMessage(error);
			showToastMessage({
				type: "error",
				title: "Error",
				description: errMsg || "An error occurred",
			});
		}
	};
	const getAuthCode = async () => {
		setLoading({ id: "getAuthCode" });
		try {
			const result = await getCreditCode(token);
			showToastMessage({
				type: "success",
				title: "Success",
				description: result?.message,
			});
			setLoading({ id: "" });
		} catch (error) {
			setLoading({ id: "" });
			const errMsg = errorMessage(error);
			showToastMessage({
				type: "error",
				title: "Error",
				description: errMsg || "An error occurred",
			});
		}
	};
	useEffect(() => {
		setRouteName("ManualCredit");
	}, []);
	return (
		<div className="form-div">
			<div>
				<label className="thresh-label">User Email</label>
				<input
					onChange={(e) =>
						setFormData((prev) => ({ ...prev, email: e.target.value }))
					}
					placeholder=""
					value={formData?.email}
					className="credit-input"
				/>
			</div>
			<div>
				<label className="thresh-label">Email Credit</label>
				<input
					onChange={(e) =>
						setFormData((prev) => ({ ...prev, credit: e.target.value }))
					}
					placeholder=""
					value={formData?.credit}
					className="credit-input"
				/>
			</div>
			<div className="with-btn">
				<label className="thresh-label">Authorization Code</label>
				<input
					onChange={(e) =>
						setFormData((prev) => ({ ...prev, code: e.target.value }))
					}
					placeholder=""
					value={formData?.code}
					className="credit-input"
				/>
				<Button
					onClick={() => getAuthCode()}
					loading={loading.id === "getAuthCode"}
				>
					Get Code
				</Button>
			</div>
			<Button
				disabled={!formData?.email || !formData?.code || !formData?.credit}
				onClick={() => creditUser()}
				className="credit-btn"
				loading={loading.id === "credit"}
			>
				Credit User
			</Button>
		</div>
	);
};

export default ManualCredit;

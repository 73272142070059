import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import { Button, Checkbox, Form, Input } from "antd";
import { LoginContainer } from "../../components/styled/Auth.styled";
import {
	login,
	loginClient,
	setAuthToken,
} from "../../service/serviceInstance";
import { errorMessage } from "../../utils/getErrors";
import { showToastMessage } from "../../utils/Toast";
import TextContent from "../../components/TextContent";

const Login = () => {
	const [loading, setLoading] = useState(false);
	const token = Cookie.get("token");

	const history = useNavigate();

	const onFinish = (values) => {
		setLoading(true);
		login(values)
			.then(
				({
					data: {
						data: { email, token },
						success,
					},
				}) => {
					setLoading(false);
					if (success) {
						Cookie.set("token", token);
						// Cookie.set("token", token, { expires: 1 });
						localStorage.setItem("userDetails", JSON.stringify({ email }));
						setAuthToken(token);
						history("/users");
						showToastMessage({
							type: "success",
							title: "Welcome 😊",
							description: email,
						});
					} else {
						showToastMessage({
							type: "error",
							title: "Access Denied",
							description: "Unable to log you in",
						});
					}
				}
			)
			.catch((error) => {
				setLoading(false);
				const errMsg = errorMessage(error);
				showToastMessage({
					type: "error",
					title: "Error",
					description: errMsg || "Unable to process your request",
					// description: 'Unable to process your request',
				});
			});
	};

	if (token) {
		return <Navigate to="/users" replace />;
	}
	const onFinishFailed = () => {};
	return (
		<LoginContainer>
			<div>
				<img src="/asset/icons/maildrip-logo.svg" alt="" />
				<section className="login_sect">
					<div>
						<TextContent>Welcome back</TextContent>
						<TextContent>We need some information to get access </TextContent>
					</div>
					<Form
						name="basic"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
						size="large"
						layout="vertical"
					>
						<Form.Item
							name="email"
							label="Your e-mail"
							rules={[
								{
									required: true,
									message: "Please input your email!",
									type: "email",
								},
							]}
						>
							<Input placeholder="What is your email" />
						</Form.Item>

						<Form.Item
							label="Password"
							name="password"
							rules={[
								{
									required: true,
									message: "Please input your password!",
								},
							]}
						>
							<Input.Password placeholder="password" />
						</Form.Item>
						{/* <Link to="/forgotPassword" className="text-primary text-sm hover:text-primary  hover:underline">
                    Forgot my password
                  </Link> */}

						<Form.Item name="remember" valuePropName="checked">
							<Checkbox>Remember me</Checkbox>
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit" loading={loading}>
								Log In
							</Button>
						</Form.Item>
					</Form>
				</section>
			</div>
		</LoginContainer>
	);
};

export default Login;

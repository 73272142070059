import styled from "styled-components";
import Colour from "../../utils/Colour";

export const UsersPageContain = styled.div``;

export const UsersTableContain = styled.div`
	& .ant-table-thead .ant-table-cell {
		color: ${Colour.fadeBlack};
	}

	& .ant-table-tbody > tr > td,
	.ant-table tfoot > tr > th,
	.ant-table tfoot > tr > td {
		padding: 16px;
	}
`;
export const BillingHistoryContain = styled.div`
	& > span {
		margin-bottom: 10px;
		display: inline-block;
	}
	& .ant-table-tbody > tr > td,
	.ant-table tfoot > tr > th,
	.ant-table tfoot > tr > td {
		padding: 16px;
	}
`;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import PageNotFound from "./pages/404";
import {  useState } from "react";
import { routes } from "./utils/routes";
import Users from "./pages/Users";
import User from "./pages/User";
import Comments from "./pages/Comments";
import Login from "./pages/auth/Login";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import ReportPage from "./pages/ReportPage";
import ManualCredit from "./pages/ManualCredit";

function App() {
	const [routeName, setRouteName] = useState("");

	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path={routes.login} element={<Login />} />
					<Route path="*" element={<PageNotFound />} />
					<Route element={<ProtectedRoute routeName={routeName} />}>
						<Route
							path={routes.comments}
							element={<Comments setRouteName={setRouteName} />}
						/>
						<Route
							path={routes.user}
							element={<User setRouteName={setRouteName} />}
						/>
						<Route
							path={routes.users}
							element={<Users setRouteName={setRouteName} />}
						/>
						<Route
							path={routes.dashboard}
							element={<Dashboard setRouteName={setRouteName} />}
						/>
            <Route
              path={routes.manualCredit}
              element={<ManualCredit setRouteName={setRouteName} />}
              />
                 <Route
              path={routes.reportPage}
              element={<ReportPage setRouteName={setRouteName} />

}
						/>
					</Route>
				</Routes>
			</Router>
		</div>
	);
}

export default App;

import { Modal as AntModal } from "antd";
import styled from "styled-components";
import TextContent from "./TextContent";

const Body = styled.div`
	> span:first-child {
		margin-bottom: 32px;
	}
`;

const Modal = ({ isActive, onCancel, onOkay, okText, cancelText, title, children }) => {
	return (
		<>
			<AntModal
				visible={isActive}
				onCancel={onCancel}
				onOkay={onOkay}
				okText={okText}
				cancelText={cancelText}
				footer={null}
				closable={false}
				width={480}
				centered
				destroyOnClose
				wrapClassName="cust-modal-class"
			>
				<Body>
					<TextContent fontSize={24} fontWeight="600" textAlign="center" display="block">
						{title}
					</TextContent>
					{children}
				</Body>
			</AntModal>
		</>
	);
};

export default Modal;

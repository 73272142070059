export function thousandFormatter(number) {
  const parts = parseFloat(number).toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  let continous = parts.join('.')
  return continous ;
}

export function numberFormatter(number) {
  const parts = parseFloat(number).toFixed(0).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function formatNumber(number) {
  // console.log(number);
  return parseInt(number).toLocaleString('en-US');
}
